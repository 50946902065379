<template>
	<v-overlay :visible="visible" @close="$emit('close')">
		<div class="flex space-between align-center mb-30">
			<div>
				<h1 class="mb-10">Finn tekningarútskrift</h1>

				<p class="color-grey">Finn tekningarútskriftina hjá felagnum og legg afturat skjølum.</p>
			</div>

			<div class="overlay-close" @click.prevent="$emit('close')"></div>
		</div>

		<div class="search-input relative z-50">
			<input type="text" v-model="query" @keydown.enter.prevent="search" />

			<div class="icon-search pointer-events-none"></div>

			<div v-if="results.length" class="absolute w-100 flex flex-column mt-10 rounded background-white border-light py-10">
				<a v-for="result in results" :key="result.name" href="#" @click.prevent="select(result)" class="flex flex-column py-10 px-20 border-bottom hover:background-light-grey-100 last-child:border-none">
					<p class="mb-5">{{ result.name }}</p>

					<p class="color-grey">Skrás.nr.: {{ result.registrationNumber }}</p>
				</a>
			</div>
		</div>

		<div class="flex justify-end mt-20">
			<div class="btn inline-block" :class="{ disabled: selected == null || loading }" @click="save">{{ loading ? 'Bíða við...' : 'Legg í skjøl' }}</div>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';

export default {
	name: 'LogOverlay',

	props: {
		value: Object,
		visible: Boolean,
		customerUuid: String,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			loading: false,
			query: '',
			results: [],
			debounce: null,
			selected: null,
			preventSearch: false,
		};
	},

	watch: {
		query: function (value) {
			clearTimeout(this.debounce);

			if (this.preventSearch) {
				return;
			}

			this.selected = null;

			if (!value) {
				this.results = [];

				return;
			}

			this.debounce = setTimeout(() => {
				this.search();
			}, 250);
		},

		visible: function (value) {
			if (value) {
				return;
			}

			this.query = '';
			this.results = [];
			this.selected = null;
		},
	},

	methods: {
		search() {
			axios
				.get(`/customers/skraseting/${this.query}`)
				.then((result) => {
					this.results = result.data.slice(0, 10);
				})
				.catch((error) => {
					console.log('Error in SkrasetingOverlay.vue search', error);
				});
		},

		select(result) {
			if (!result) {
				return;
			}

			this.query = result.name;
			this.results = [];
			this.preventSearch = true;

			this.$nextTick(() => {
				this.selected = result;
				this.preventSearch = false;
			});
		},

		save() {
			if (this.selected == null) {
				return;
			}

			const { id, s, registrationNumber } = this.selected;

			if (!id || !s || !registrationNumber) {
				return;
			}

			this.loading = true;

			axios
				.get(`/customers/skraseting/download?id=${id}&s=${s}&registrationNumber=${registrationNumber}&customerUUID=${this.customerUuid}`)
				.then(() => {
					this.$emit('close');
					this.$emit('add');
				})
				.catch((error) => {
					console.log('Error in SkrasetingOverlay.vue save()', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
