<template>
	<v-overlay :visible="visible" @close="close()">
		<div class="flex space-between align-center mb-40">
			<div>
				<h1 class="mb-10">Legg í bólk</h1>

				<p class="color-grey">Legg kundan í bólk</p>
			</div>

			<div class="overlay-close" @click="close()"></div>
		</div>

		<div class="flex space-between align-center">
			<select v-model="messageGroup">
				<option value="">Vel bólk</option>

				<option v-for="messageGroup in filteredMessageGroups" :key="messageGroup.id" :value="messageGroup.id">
					{{ messageGroup.name }}
				</option>
			</select>

			<button class="btn small" @click="add" :class="{ disabled: !messageGroup }">Legg í bólk</button>
		</div>
	</v-overlay>
</template>

<script>
import axios from 'axios';

import VOverlay from '@/components/VOverlay.vue';

export default {
	name: 'AddToMessageGroupOverlay',

	props: {
		visible: Boolean,
		customerId: Number,
		// the Message Group that the customer is already a part of (to be filtered out)
		messageGroups: Array,
	},

	components: {
		VOverlay,
	},

	data() {
		return {
			messageGroup: '',
			allMessageGroups: [],
		};
	},

	mounted() {
		// Get all Message Groups
		axios
			.get('/message-groups')
			.then((response) => {
				this.allMessageGroups = response.data;
			})
			.catch((error) => {
				console.error(error);
			});
	},

	computed: {
		filteredMessageGroups() {
			return this.allMessageGroups.filter((messageGroup) => !this.messageGroups.map((messageGroup) => messageGroup.id).includes(messageGroup.id));
		},
	},

	methods: {
		close() {
			this.$emit('close');
		},

		add() {
			if (!this.messageGroup) {
				return;
			}

			// Add Customer to Message Group
			axios
				.post(`/message-groups/${this.messageGroup}/add-customer/${this.customerId}`)
				.then((response) => {
					this.$emit('add', response.data);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.$emit('close');
				});
		},
	},
};
</script>

<style lang="scss" scoped>
select {
	padding: 12px;
	background: white;
	border: 1px solid #ddd;
	border-radius: 8px;
}
</style>
