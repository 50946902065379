<template>
	<div>
		<div class="flex justify-center mt-80" v-if="loading">
			<i class="fas fa-spinner fa-spin fa-2x color-blue"></i>
		</div>

		<main class="container-wide mb-40" v-if="!loading">
			<div class="flex space-between mb-40">
				<div>
					<h1 class="mb-10">{{ customer.name }}</h1>

					<p class="color-grey">{{ customer.otherName }}</p>
				</div>

				<div class="flex">
					<div class="mr-10">
						<router-link
							class="btn-round white far fa-edit"
							:to="{ name: 'CustomerEdit', params: { id: customer.id } }"
							title="Dagfør kundan"
						></router-link>
					</div>

					<div class="mr-10">
						<Mail :customer="customer" :files="customer.files" type="Kunda" />
					</div>

					<div class="relative">
						<div @click="contextMenu.active = !contextMenu.active" class="btn-round cursor-pointer">
							<i class="fas fa-ellipsis-v pointer-events-none"></i>
						</div>

						<div class="context-menu" :class="{ active: contextMenu.active }">
							<router-link :to="{ name: 'AgreementCreate', params: { customerId: customer.id } }">Stovna avtalu</router-link>

							<a href="#" @click.prevent="addToMessageGroupOverlay.active = true">Legg í bólk</a>

							<a href="#" @click.prevent="skrasetingOverlay.active = true">Finn tekningarútskrift</a>
						</div>
					</div>
				</div>
			</div>

			<SkrasetingOverlay
				:visible="skrasetingOverlay.active"
				:customer-uuid="customer.uuid"
				@add="addSkrasetingFile"
				@close="skrasetingOverlay.active = false"
			/>

			<AddToMessageGroupOverlay
				:visible="addToMessageGroupOverlay.active"
				:customer-id="customer.id"
				:message-groups="customer.messageGroups"
				@add="customer.messageGroups = [...customer.messageGroups, $event]"
				@close="addToMessageGroupOverlay.active = false"
			/>

			<div class="text-grey grid grid-2 grid-gap-80 mb-10">
				<div>
					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('companyType')" class="color-blue mr-5">*</span>

							Virkisslag
						</div>

						<div>{{ companyType }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('name')" class="color-blue mr-5">*</span>

							Lóggildugt navn
						</div>

						<div>{{ name }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('otherName')" class="color-blue mr-5">*</span>

							Hjánavn
						</div>

						<div>{{ otherName }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('address')" class="color-blue mr-5">*</span>

							Bústaður
						</div>

						<div>{{ address }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('zip')" class="color-blue mr-5">*</span>

							Postnummar
						</div>

						<div>{{ zip }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('city')" class="color-blue mr-5">*</span>

							Bygd/býur
						</div>

						<div>{{ city }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('email')" class="color-blue mr-5">*</span>

							Teldupostur
						</div>

						<div>{{ email }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('phoneNumber')" class="color-blue mr-5">*</span>

							Telefonnummar
						</div>

						<div>{{ phoneNumber }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('mobileNumbers')" class="color-blue mr-5">*</span>

							Fartelefonnummur
						</div>

						<div>{{ mobileNumbers }}</div>
					</div>
				</div>

				<div>
					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('vtal')" class="color-blue mr-5">*</span>

							V-tal
						</div>

						<div>{{ vtal }}</div>
					</div>

					<div class="mb-10 flex space-between">
						<div class="heavy">
							<span v-if="isChanged('registrationNumber')" class="color-blue mr-5">*</span>

							Skrásetingarnummar
						</div>

						<div>{{ registrationNumber }}</div>
					</div>

					<div class="flex space-between mb-10">
						<div class="heavy">
							<span v-if="isChanged('economicId')" class="color-blue mr-5">*</span>

							E-conomic Id
						</div>

						<div class="text-right">
							{{ customer.economicId }}
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div class="heavy">
							<span v-if="isChanged('mcc')" class="color-blue mr-5">*</span>

							MCC
						</div>

						<div class="text-right">
							{{ customer.mcc ? customer.mcc : '-' }}
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div class="heavy">
							<span v-if="isChanged('tcs_username')" class="color-blue mr-5">*</span>

							TCS brúkaranavn
						</div>

						<div class="text-right">
							{{ customer.tcs_username ? customer.tcs_username : '-' }}
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div class="heavy">
							<span v-if="isChanged('tcs_email')" class="color-blue mr-5">*</span>

							TCS teldubústaður
						</div>

						<div class="text-right">
							{{ customer.tcs_email ? customer.tcs_email : '-' }}
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div class="heavy">
							<span v-if="isChanged('tcs_phone')" class="color-blue mr-5">*</span>

							TCS fartelefon
						</div>

						<div class="text-right">
							{{ customer.tcs_phone ? customer.tcs_phone : '-' }}
						</div>
					</div>
				</div>
			</div>

			<div v-if="customer.changes">
				<div class="mb-5 text-right color-grey-700" style="font-size: 13px">* Rættingar eru gjørdar</div>

				<div class="buttons flex justify-end">
					<div class="btn" @click="updateCustomer" :class="{ disabled: changesLoading }">
						<i v-show="changesLoading" class="fas fa-spinner fa-spin mr-5"></i>

						Góðtak rættingar
					</div>
				</div>
			</div>

			<hr class="my-50" />

			<div class="add">
				<div class="flex space-between align-center mb-10">
					<h2>Avtalur</h2>

					<router-link :to="{ name: 'AgreementCreate', params: { customerId: customer.id } }" class="btn-round small cursor-pointer"
						><i class="fas fa-plus"></i
					></router-link>
				</div>

				<div class="flex mb-10">
					<div class="mr-30">
						<label class="inline-flex align-center cursor-pointer">
							<div class="switch" :class="{ active: showActiveAgreements }">
								<input type="checkbox" v-model="showActiveAgreements" />

								<div></div>
							</div>

							<span class="ml-5">Vís virknar</span>
						</label>
					</div>

					<div>
						<label class="inline-flex align-center cursor-pointer">
							<div class="switch" :class="{ active: showShortTermAgreements }">
								<input type="checkbox" v-model="showShortTermAgreements" />

								<div></div>
							</div>

							<span class="ml-5">Vís stutttíðarleigur</span>
						</label>
					</div>
				</div>

				<div v-if="!agreements || !agreements.length" class="color-grey my-30">Ongin avtala varð funnin</div>

				<div v-if="agreements && agreements.length" class="mb-50">
					<table>
						<thead>
							<th>Slag</th>

							<th>Byrjar</th>

							<th>Endar</th>

							<th class="right">&nbsp;</th>
						</thead>

						<tbody>
							<tr v-for="(agreement, index) in agreements" :key="`agreement_${index}`">
								<td>
									<router-link v-if="agreement.type" :to="{ name: 'AgreementShow', params: { id: agreement.id } }">
										{{ agreement.type.name }}
									</router-link>

									<router-link v-if="!agreement.type" :to="{ name: 'AgreementShow', params: { id: agreement.id } }">-</router-link>
								</td>

								<td>{{ agreement.from ? new Date(agreement.from).format('%d/%m/%y %H:%S') : '-' }}</td>

								<td>{{ agreement.to ? new Date(agreement.to).format('%d/%m/%y %H:%S') : '-' }}</td>

								<td class="right">
									<router-link
										v-if="agreement.agreementLink && agreement.agreementLink.active"
										:to="{ name: 'Client', query: { token: agreement.agreementLink && agreement.agreementLink.token } }"
										target="_blank"
									>
										<i class="fas fa-link color-green" title="Einnýtisleinkja"></i>
									</router-link>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<hr class="my-50" />

			<div class="add">
				<div class="flex space-between align-center mb-10">
					<h2>Persónar</h2>

					<div class="btn-round small cursor-pointer" @click="ownerOverlay.visible = true"><i class="fas fa-plus"></i></div>
				</div>

				<div v-if="!customer.owners || !customer.owners.length" class="color-grey my-30">Ongin eigari varð funnin</div>

				<div v-if="customer.owners && customer.owners.length" class="mb-50">
					<table>
						<thead>
							<th>Navn</th>

							<th>P-tal</th>

							<th>Ognarskap</th>

							<th class="right"></th>
						</thead>

						<tbody>
							<tr v-for="(owner, index) in customer.owners" :key="`owners_${index}`">
								<td
									@click="
										() => {
											ownerOverlay.owner = owner;
											ownerOverlay.visible = true;
										}
									"
									class="cursor-pointer"
								>
									{{ owner.name }}
								</td>

								<td>{{ owner.ssn }}</td>

								<td v-if="owner.ownership">{{ owner.ownership }}%</td>
								<td v-if="!owner.ownership">-</td>

								<td class="right">
									<i class="fas fa-trash-alt hover:color-red cursor-pointer" title="Strika" @click="deleteOwner(owner.id)"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<OwnerOverlay
				v-model="ownerOverlay.owner"
				:customer-uuid="customer.uuid"
				:visible="ownerOverlay.visible"
				:editing="!!ownerOverlay.owner.id"
				@saveOwner="saveOwner"
				@close="resetOwnerOverlay"
			/>

			<hr class="my-50" />

			<h2 class="mb-10">Terminalar</h2>

			<div v-if="!agreementTerminals || !agreementTerminals.length" class="color-grey my-30">Ongin terminalur varð funnin</div>

			<div v-if="agreementTerminals && agreementTerminals.length" class="mb-50">
				<table>
					<thead>
						<th>Verifone SR</th>

						<th>Byrjar</th>

						<th>Endar</th>

						<th>Avtala</th>

						<th></th>
					</thead>

					<tbody>
						<tr v-for="(agreementTerminal, index) in agreementTerminals" :key="`terminal-${index}`">
							<td v-if="agreementTerminal.terminal">
								<router-link :to="{ name: 'TerminalShow', params: { id: agreementTerminal.terminal.id } }">
									{{ agreementTerminal.terminal.verifoneSerialnumber }}

									<i v-if="agreementTerminal.terminal.lease" class="fas fa-info-circle color-blue ml-5" title="Leiguterminalur"></i>
								</router-link>
							</td>

							<td v-if="!agreementTerminal.terminal">-</td>

							<td>{{ new Date(agreementTerminal.from).format('%d/%m/%y %H:%S') }}</td>

							<td>{{ new Date(agreementTerminal.to).format('%d/%m/%y %H:%S') }}</td>

							<td>
								<router-link
									:to="{ name: 'AgreementShow', params: { id: agreementTerminal.agreementId } }"
									class="color-grey-600 hover:color-grey-800"
									>Far til avtalu <i class="fas fa-arrow-right"></i
								></router-link>
							</td>

							<td class="right">
								<i
									v-if="Date.now() > new Date(agreementTerminal.to)"
									class="far fa-times-circle color-red mr-20"
									title="Avtalan er ikki aktiv"
								></i>

								<i
									v-if="Date.now() <= new Date(agreementTerminal.to)"
									class="far fa-check-circle color-green mr-20"
									title="Avtalan er aktiv"
								></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<hr class="my-50" />

			<div class="add">
				<div class="flex space-between align-center mb-10">
					<h2>Kontur</h2>

					<div @click="bank.overlay = true" class="btn-round small cursor-pointer"><i class="fas fa-plus"></i></div>
				</div>

				<div v-if="!customer.bankAccounts || !customer.bankAccounts.length" class="color-grey my-30">Ongin konta varð funnin</div>

				<div v-if="customer.bankAccounts && customer.bankAccounts.length" class="mb-50">
					<table>
						<thead>
							<th>Reg nr.</th>

							<th>Konto nr.</th>

							<th class="text-right">Bankaváttan</th>

							<th></th>
						</thead>

						<tbody>
							<tr v-for="(account, index) in customer.bankAccounts" :key="`bank-account-${index}`">
								<td>{{ account.registrationNumber }}</td>

								<td>{{ account.accountNumber }}</td>

								<td>
									<!-- If no verification file -->
									<div v-if="!account.accountVerificationFile" class="flex items-center justify-end">
										<div @click="downloadVerificationFile(account)" class="hover:color-blue cursor-pointer mr-20">
											<i class="fas fa-file-download"></i>

											Tak skjal niður
										</div>

										<div @click="openVerificationFileInput(index)" class="hover:color-blue cursor-pointer">
											<i class="fas fa-upload"></i>

											Legg undirskrivað skjal upp
										</div>
									</div>

									<!-- If verification file -->
									<div v-if="account.accountVerificationFile" class="flex items-center justify-end">
										<div @click="downloadVerificationFileWithSignature(account)" class="hover:color-blue cursor-pointer mr-20">
											<i class="fas fa-file-download"></i>

											Tak skjal niður
										</div>

										<div @click="deleteFile(index, 'accountverificationfile', account.id)" class="hover:color-red cursor-pointer">
											<i class="fas fa-trash-alt"></i>

											Strika skjal
										</div>
									</div>
								</td>

								<td class="text-right">
									<i class="fas fa-trash-alt hover:color-red cursor-pointer" title="Strika konto" @click="deleteAccount(account)"></i>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<a ref="anchor" href="#" download="true"></a>

			<input type="file" ref="verificationFileInput" class="display-none" data-id="" accept="application/pdf" @change="uploadVerificationFile" />

			<a href="#" ref="verificationFileLink" class="display-none" target="_blank" />

			<AddBankOverlay :visible="bank.overlay" :customer-id="customer.id" @add="addAccount" @close="bank.overlay = false" />

			<hr class="my-50" />

			<div class="mb-40 add">
				<div class="flex space-between">
					<h2>Skjøl</h2>

					<div class="btn-round small">
						<i class="fas fa-plus"></i>

						<input type="file" id="file" ref="file" @change="uploadFile" />
					</div>
				</div>

				<div v-if="!files || !files.length" class="color-grey my-20">Ongi skjøl vórðu funnin</div>

				<table v-if="files && files.length">
					<thead>
						<th>Navn</th>

						<th>Uploadað</th>

						<th>Brúkari</th>

						<th>Stødd</th>

						<th>Vátta</th>

						<th>Váttað av</th>

						<th></th>
					</thead>

					<tbody>
						<tr v-for="(file, index) in files" :key="`file-${index}`">
							<td style="max-width: 300px">
								<div class="line-clamp-1 hover:color-blue cursor-pointer" :title="file.original_name" @click="downloadFile(file)">
									<i class="far fa-file-word color-blue" v-if="file.extension == 'docx'"></i>
									<i class="far fa-file-excel color-blue" v-else-if="file.extension == 'xlsx'"></i>
									<i class="far fa-file-pdf color-blue" v-else-if="file.extension == 'pdf'"></i>
									<i class="far fa-file-image color-blue" v-else-if="file.extension == 'png'"></i>
									<i class="far fa-file-image color-blue" v-else-if="file.extension == 'jpg'"></i>
									<i class="far fa-file-image color-blue" v-else-if="file.extension == 'jpeg'"></i>
									<i class="far fa-file color-blue" v-else></i>

									<span class="ml-5">
										{{ decodeURI(file.original_name) }}

										<span v-if="!file.confirmedAt" class="ml-5 color-blue">
											<i class="fas fa-exclamation-circle" title="Nýtt skjal"></i>
										</span>
									</span>
								</div>
							</td>

							<td>
								{{ new Date(file.createdAt).format('%d/%m/%y %H:%M') }}
							</td>

							<td>
								{{ file.user ? file.user.firstName : '[KUNDIN]' }}
							</td>

							<td>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</td>

							<td>
								<input type="checkbox" @change="confirmFile($event, file)" :checked="!!file.confirmedAt" class="cursor-pointer" />
							</td>

							<td>{{ getConfirmedBy(file) }}</td>

							<td class="right">
								<div>
									<i class="fas fa-file-download mr-20 hover:color-blue cursor-pointer" title="Tak niður" @click="downloadFile(file)"></i>

									<i class="fas fa-trash-alt hover:color-red cursor-pointer" title="Strika" @click="confirmDeleteFile(file)"></i>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<hr class="my-50" />

			<div class="mb-40">
				<h2>Uppgerðir</h2>

				<div v-if="!transactionSettlementFiles || !transactionSettlementFiles.length" class="color-grey my-20">Vit funnu ongar uppgerðir</div>

				<table v-if="transactionSettlementFiles && transactionSettlementFiles.length">
					<thead>
						<th>Navn</th>

						<th>Uploadað</th>

						<th>Stødd</th>

						<th>Fakturanummar</th>

						<th></th>
					</thead>

					<tbody>
						<tr v-for="(file, index) in transactionSettlementFiles" :key="`file-${index}`">
							<td style="max-width: 300px">
								<div class="line-clamp-1 hover:color-blue cursor-pointer" :title="file.original_name" @click="downloadFile(file)">
									<i class="far fa-file-pdf color-blue"></i>

									<span class="ml-5">
										{{ decodeURI(file.original_name) }}
									</span>
								</div>
							</td>

							<td>
								{{ new Date(file.createdAt).format('%d/%m/%y %H:%M') }}
							</td>

							<td>{{ (file.size / 1024 / 1024).toFixed(2) }} MB</td>

							<td>
								<a
									v-if="file.transactionSettlementFile"
									:href="`https://secure.e-conomic.com/secure/include/visfaktura.asp?bogf=1&faknr=${file.transactionSettlementFile.economicBookedInvoiceNumber}`"
									target="_blank"
								>
									{{ file.transactionSettlementFile.economicBookedInvoiceNumber }}
								</a>
							</td>

							<td class="right">
								<div>
									<i class="fas fa-file-download hover:color-blue cursor-pointer" title="Tak niður" @click="downloadFile(file)"></i>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<hr class="my-50" />

			<h2 class="mb-10">Hald</h2>

			<div v-if="subscriptions.value.length" class="mb-50">
				<table>
					<thead>
						<th>Navn</th>

						<th>Byrjar</th>

						<th>Endar</th>

						<th class="right">Lýsing</th>
					</thead>

					<tbody>
						<tr v-for="(subscription, index) in subscriptions.value" :key="index">
							<td>{{ subscription.subscription.Name }}</td>

							<td>{{ new Date(subscription.subscriptionData.StartDate).format('%d. %mn %y') }}</td>

							<td>{{ new Date(subscription.subscriptionData.EndDate).format('%d. %mn %y') }}</td>

							<td class="right">{{ subscription.subscriptionData.ExtraTextForInvoice }}</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div v-if="subscriptions.loading" class="flex justify-center color-grey my-30"><i class="fas fa-spinner fa-spin fa-lg color-blue"></i></div>

			<div v-if="!subscriptions.value.length && !subscriptions.loading" class="color-grey my-30">Kundin hevur ongi hald</div>

			<hr class="my-50" />

			<h2 class="mb-10">Fakturar</h2>

			<div v-if="invoices.value.length">
				<table>
					<thead>
						<th>Nummar</th>

						<th>Dato</th>

						<th class="right">Upphædd</th>

						<th class="right">MVG</th>

						<th class="right">Samlað</th>

						<th class="right">&nbsp;</th>
					</thead>

					<tbody>
						<tr v-for="(invoice, index) in invoices.value" :key="`invoice-${index}`">
							<td>#{{ invoice.bookedInvoiceNumber }}</td>

							<td>{{ new Date(invoice.date).format('%d. %mn %y') }}</td>

							<td class="right">{{ formatMoney(invoice.netAmount) }} kr.</td>

							<td class="right">{{ formatMoney(invoice.vatAmount) }} kr.</td>

							<td class="right">{{ formatMoney(invoice.grossAmount) }} kr.</td>

							<td class="right">
								<a
									:href="`https://secure.e-conomic.com/secure/include/visfaktura.asp?bogf=1&faknr=${invoice.bookedInvoiceNumber}`"
									target="_blank"
									><i class="far fa-eye cursor-pointer"></i
								></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div v-if="invoices.loading" class="color-grey my-30">Leitar eftir fakturum...</div>

			<div v-if="!invoices.value.length && !invoices.loading" class="color-grey my-30">Ongin fakturi varð funnin</div>

			<hr class="my-50" />

			<div class="add">
				<div class="flex space-between align-center mb-10">
					<h2>Boð bólkar</h2>

					<div @click="addToMessageGroupOverlay.active = true" class="btn-round small cursor-pointer"><i class="fas fa-plus"></i></div>
				</div>

				<div v-if="!customer.messageGroups || !customer.messageGroups.length" class="color-grey my-30">Ongin terminalur varð funnin</div>

				<table v-if="customer.messageGroups && customer.messageGroups.length">
					<thead>
						<th>Navn á bólki</th>

						<th class="right">&nbsp;</th>
					</thead>

					<tbody>
						<tr v-for="(messageGroup, index) in customer.messageGroups" :key="`messageGroup-${index}`">
							<td>{{ messageGroup.name }}</td>

							<td class="right">
								<i class="fas fa-trash-alt hover:color-red cursor-pointer" title="Strika" @click="removeFromMessageGroup(messageGroup.id)"></i>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</main>
	</div>
</template>

<script>
import axios from 'axios';

import OwnerOverlay from '../components/OwnerOverlay.vue';
import AddBankOverlay from '../components/AddBankOverlay.vue';
import AddToMessageGroupOverlay from '../components/AddToMessageGroupOverlay.vue';
import SkrasetingOverlay from '../components/SkrasetingOverlay.vue';
import Mail from '../components/Mail.vue';

export default {
	name: 'CustomerShow',

	components: {
		OwnerOverlay,
		AddBankOverlay,
		AddToMessageGroupOverlay,
		SkrasetingOverlay,
		Mail,
	},

	data() {
		return {
			contextMenu: {
				active: false,
			},
			ownerOverlay: {
				owner: {
					isOwner: false,
					canSign: false,
					name: '',
					email: '',
					ssn: '',
					livingCountry: 'Føroyar',
					taxationCountry: 'Føroyar',
					pep: 0,
					ownership: '',
					file: null,
				},
				visible: false,
			},
			changesLoading: false,
			addToMessageGroupOverlay: {
				active: false,
			},
			skrasetingOverlay: {
				active: false,
			},
			showActiveAgreements: false,
			showShortTermAgreements: false,
			loading: true,
			customerId: null,
			invoices: {
				value: [],
				loading: true,
			},
			customer: {
				//
			},
			subscriptions: {
				value: [],
				loading: true,
			},
			bank: {
				overlay: false,
			},
		};
	},

	computed: {
		agreementTerminals() {
			const agreementTerminals = [];

			if (this.customer && this.customer.agreements) {
				this.customer.agreements.forEach((agreement) => {
					if (agreement.agreementTerminals) {
						agreement.agreementTerminals.forEach((agreementTerminal) => {
							agreementTerminals.push(agreementTerminal);
						});
					}
				});
			}

			return agreementTerminals;
		},

		// Filter out Transaction Settlement Files
		files() {
			return this.customer.files.filter((file) => !file.transactionSettlementFileId);
		},

		// Only Transaction transactionSettlementFiles
		transactionSettlementFiles() {
			return this.customer.files.filter((file) => file.transactionSettlementFileId);
		},

		agreements() {
			let agreements = this.customer.agreements;

			agreements = agreements.filter((agreement) => !agreement.isArchived);

			if (!this.showActiveAgreements) {
				agreements = agreements.filter((agreement) => !agreement.active);
			}

			if (!this.showShortTermAgreements) {
				agreements = agreements.filter((agreement) => agreement.type?.name != 'Stutttíðarleiga');
			}

			return agreements;
		},

		companyType() {
			return this.isChanged('companyType') ? this.isChanged('companyType') : this.customer && this.customer.companyType ? this.customer.companyType : '-';
		},

		name() {
			return this.isChanged('name') ? this.isChanged('name') : this.customer && this.customer.name ? this.customer.name : '-';
		},

		otherName() {
			return this.isChanged('otherName') ? this.isChanged('otherName') : this.customer && this.customer.otherName ? this.customer.otherName : '-';
		},

		address() {
			return this.isChanged('address') ? this.isChanged('address') : this.customer && this.customer.address ? this.customer.address : '-';
		},

		zip() {
			return this.isChanged('zip') ? this.isChanged('zip') : this.customer && this.customer.zip ? this.customer.zip : '-';
		},

		city() {
			return this.isChanged('city') ? this.isChanged('city') : this.customer && this.customer.city ? this.customer.city : '-';
		},

		email() {
			return this.isChanged('email') ? this.isChanged('email') : this.customer && this.customer.email ? this.customer.email : '-';
		},

		phoneNumber() {
			return this.isChanged('phoneNumber') ? this.isChanged('phoneNumber') : this.customer && this.customer.phoneNumber ? this.customer.phoneNumber : '-';
		},

		mobileNumbers() {
			return this.isChanged('mobileNumbers')
				? this.isChanged('mobileNumbers')
				: this.customer && this.customer.mobileNumbers
				? this.customer.mobileNumbers
				: '-';
		},

		vtal() {
			return this.isChanged('vtal') ? this.isChanged('vtal') : this.customer && this.customer.vtal ? this.customer.vtal : '-';
		},

		registrationNumber() {
			return this.isChanged('registrationNumber')
				? this.isChanged('registrationNumber')
				: this.customer && this.customer.registrationNumber
				? this.customer.registrationNumber
				: '-';
		},
	},

	async mounted() {
		this.customerId = this.$route.params.id;

		window.addEventListener('click', (e) => {
			if (!e.target.classList.contains('btn-round')) {
				this.contextMenu.active = false;
			}
		});

		await axios
			.get(`/customers/${this.customerId}`)
			.then((response) => {
				this.customer = response.data;

				console.log(this.customer);

				// reverse the changes to get the latest values
				this.customer.changes?.reverse?.();

				this.customer.files.sort((a, b) => {
					return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
				});
			})
			.catch((error) => {
				console.log('error fetching customer', error);
			});

		this.loading = false;

		await axios
			.get(`/customers/${this.customerId}/invoices`)
			.then((response) => {
				this.invoices.value = response.data.collection.reverse();
			})
			.catch((error) => {
				console.log('error fetching customer', error);
			})
			.finally(() => {
				this.invoices.loading = false;
			});

		await axios
			.get(`/customers/economic/get-customer-subscriptions/${this.customer.economicId}`)
			.then((response) => {
				this.subscriptions.value = response.data;
			})
			.catch((error) => {
				if (error.response?.status === 404) {
					console.log('This customer does not have any subscriptions.');

					return;
				}

				console.log('%cError fetching customer subscriptions in CustomerShow.vue', 'font-size: 14px; font-weight: 700; background: red; color: white;');
				console.log(error);
			})
			.finally(() => {
				this.subscriptions.loading = false;
			});
	},

	methods: {
		getConfirmedBy(file) {
			if (!file.confirmedBy) {
				return '-';
			}

			return `${file.confirmedBy.firstName} ${file.confirmedBy.lastName}`;
		},

		resetOwnerOverlay() {
			this.ownerOverlay.owner = {
				isOwner: false,
				canSign: false,
				name: '',
				email: '',
				ssn: '',
				livingCountry: 'Føroyar',
				taxationCountry: 'Føroyar',
				pep: 0,
				ownership: '',
				file: null,
			};

			this.ownerOverlay.visible = false;
		},

		addSkrasetingFile() {
			// window.location.reload();
			this.$router.go();
		},

		isChanged(field) {
			if (this.customer && this.customer.changes) {
				for (const change of this.customer.changes) {
					if (Object.hasOwnProperty.call(change, field)) {
						return change[field];
					}
				}
			}
		},

		updateCustomer() {
			this.changesLoading = true;

			axios
				.patch(`/customers/${this.customerId}`, {
					companyType: this.companyType,
					name: this.name,
					address: this.address,
					zip: this.zip,
					city: this.city,
					email: this.email,
					phoneNumber: this.phoneNumber,
					mobileNumbers: this.mobileNumbers,
					vtal: this.vtal,
					registrationNumber: this.registrationNumber,
					economicId: this.customer.economicId,
					vatZoneNumber: this.customer.vatZoneNumber,
					currencyCode: this.customer.currencyCode,
					paymentTermNumber: this.customer.paymentTermNumber,
					customerGroupNumber: this.customer.customerGroupNumber,
				})
				.then((response) => {
					this.customer = response.data;
				})
				.catch((error) => {
					console.error('Feilur, tá vit dagføra kunda', error);
				})
				.finally(() => {
					this.changesLoading = false;
				});
		},

		addAccount(account) {
			this.customer.bankAccounts = [...this.customer.bankAccounts, account];

			this.bank.overlay = false;
		},

		deleteAccount(account) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/bank-accounts/${account.id}`)
				.then(() => {
					this.customer.bankAccounts = this.customer.bankAccounts.filter((bankAccount) => bankAccount.id != account.id);
				})
				.catch((error) => {
					console.log('Error deleting bank account', error);
				});
		},

		deleteFile(index, type, fileId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			if (type == 'accountverificationfile') {
				axios
					.delete(`/bank-accounts/${fileId}/accountverificationfile`)
					.then(() => {
						this.customer.bankAccounts[index].accountVerificationFile = null;
					})
					.catch((error) => {
						console.log('Error deleting bank account', error);
					});
			}
		},

		confirmDeleteFile(file) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/files/${file.uuid}`)
				.then(() => {
					this.customer.files = this.customer.files.filter((customerFile) => customerFile.uuid != file.uuid);
				})
				.catch((error) => {
					console.error(error.response);
				});
		},

		openVerificationFileInput(index) {
			this.$refs.verificationFileInput.dataset.id = index;

			this.$refs.verificationFileInput.click();
		},

		uploadVerificationFile(e) {
			const file = e.target.files[0];

			const formData = new FormData();

			formData.append('file', file);
			formData.append('customer_uuid', this.customer.uuid);

			this.loading = true;

			axios
				.post(`/bank-accounts/${this.customer.bankAccounts[Number(e.target.dataset.id)].id}/accountverificationfile`, formData)
				.then((response) => {
					this.loading = false;

					this.customer.bankAccounts[Number(e.target.dataset.id)].accountVerificationFile = response.data.accountVerificationFile;
				})
				.catch((error) => console.log(error));
		},

		downloadVerificationFile(account) {
			axios
				.get(`/bank-accounts/bank-verification/${account.uuid}/get-file`, {
					responseType: 'blob',
				})
				.then((response) => {
					const url = URL.createObjectURL(response.data);

					this.$refs.anchor.download = 'verify_ownership.pdf';
					this.$refs.anchor.href = url;

					this.$nextTick(() => {
						this.$refs.anchor.click();
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},

		downloadVerificationFileWithSignature(account) {
			axios
				.get(`/bank-accounts/${account.id}/accountverificationfile`)
				.then((response) => {
					const url = response.data.url;

					window.open(url, '_blank').focus();
				})
				.catch((error) => console.log(error));
		},

		downloadFile(file) {
			axios.get(`/files/${file.uuid}/url`).then((response) => {
				const link = document.createElement('a');
				link.href = response.data;
				link.setAttribute('download', file.original_name);

				document.body.appendChild(link);

				link.click();
			});
		},

		formatMoney(value) {
			return String(value.toFixed(2))
				.replace('.', ',')
				.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
		},

		removeFromMessageGroup(messageGroupId) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/message-groups/${messageGroupId}/remove-customer/${this.customer.id}`)
				.then(() => {
					this.customer.messageGroups = this.customer.messageGroups.filter((messageGroup) => messageGroup.id != messageGroupId);
				})
				.catch((error) => console.log(error));
		},

		saveOwner() {
			const owner = this.ownerOverlay.owner;

			if (this.ownerOverlay.owner.id) {
				axios
					.patch(`/owners/${owner.id}`, { ...owner })
					.then((response) => {
						console.log(response.data);
					})
					.catch((error) => console.log(error));

				return;
			}

			axios
				.post(`/owners`, { ...owner, customer: { id: this.customer.id } })
				.then((response) => {
					this.customer.owners = [...this.customer.owners, response.data];
				})
				.catch((error) => console.log(error));
		},

		deleteOwner(id) {
			if (!confirm('Ert tú vís/ur?')) {
				return;
			}

			axios
				.delete(`/owners/${id}`)
				.then(() => {
					this.customer.owners.splice(this.customer.owners.indexOf(id), 1);
				})
				.catch((error) => console.log(error));
		},

		confirmFile(e, file) {
			const checked = e.target.checked;

			axios
				.post(`/files/${file.uuid}/confirm`, {
					confirmed: checked,
				})
				.then((response) => {
					let files = [...this.customer.files];

					const index = files.findIndex((f) => f.id == file.id);

					const fileCopy = { ...files[index] };
					fileCopy.confirmedBy = response.data.confirmedBy;
					fileCopy.confirmedAt = response.data.confirmedAt;

					files.splice(index, 1, fileCopy);

					this.customer.files = files;
				})
				.catch((error) => {
					console.log('Error confirming file', error);
				});
		},

		uploadFile() {
			const formData = new FormData();

			const file = this.$refs.file.files[0];
			const fileName = `${encodeURI(file.name)}`;

			formData.append('file', new File([file], fileName, { type: file.type }));
			formData.append('customer_uuid', this.customer.uuid);

			axios
				.post(`/files`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					this.customer.files = [response.data, ...this.customer.files];
				})
				.catch((error) => {
					console.error(error.response);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.customer-subscriptions {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
}

.customer-subscription {
	flex: 0 1 calc(25% - (60px / 4));

	padding: 20px;
	background: white;
	border: 1px solid #e8e8e8;
	border-radius: 5px;

	.customer-subscription-name {
		font-weight: 700;
		font-size: 18px;
		margin-bottom: 8px;

		color: #282828;
	}

	.customer-subscription-extra {
		color: #828282;
		font-size: 14px;
	}
}

.btn-round.white {
	color: var(--color-blue);
	background: white;

	border: 1px solid #e8e8e8;

	&:hover {
		background: #f2f2f2;
	}
}

.add {
	.btn-round.small {
		transition: 0.1s opacity ease;

		opacity: 0;

		position: relative;

		width: 32px;
		height: 32px;

		cursor: pointer;

		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;

			opacity: 0;
		}
	}

	&:hover {
		.btn-round.small {
			opacity: 1;
		}
	}
}

.context-menu {
	position: absolute;
	top: 100%;
	right: 0;
	width: 180px;

	background: white;

	border: 1px solid #e8e8e8;
	border-radius: 8px;

	overflow: hidden;

	box-shadow: 0 2px 3px rgba(black, 0.05);

	transition: 0.2s all ease;

	&:not(.active) {
		transform: translateY(-8px);
		opacity: 0;
		pointer-events: none;
	}

	a {
		display: block;
		padding: 16px;

		border-bottom: 1px solid #f2f2f2;

		&:hover {
			background: #f2f2f2;
		}
	}
}
</style>
